// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import 'select2';
@import 'select2-bootstrap-theme/dist/select2-bootstrap.min.css';

// Font-Awesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";

@import "bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css";
@import "jquery-datetimepicker/jquery.datetimepicker.css";


@import "tui-color-picker/dist/tui-color-picker.min.css";
@import "tui-image-editor/dist/tui-image-editor.min.css";

